<template>
  <v-dialog
    v-model="dialog"
    :max-width="width"
    persistent
    :fullscreen="fullscreen"
  >
    <v-card outlined style=" overflow-y: scroll;">
      <v-app-bar color="blue-grey lighten-5" dense flat>
        <v-icon class="mr-2">{{ icon }}</v-icon>
        <h3>{{ title }}</h3>
        <v-spacer></v-spacer>
        <v-btn icon small @click="close()">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>
        <v-container fluid>
          <slot></slot>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <slot name="actions"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- <v-dialog
    v-model="dialog"
    :max-width="width"
    persistent
    :fullscreen="fullscreen"
  >
    <v-card outlined fill-height dense>
      <v-app-bar color="blue-grey lighten-5" dense flat>
        <v-icon class="mr-2">{{ icon }}</v-icon>
        <h3>{{ title }}</h3>
        <v-spacer></v-spacer>
        <v-btn icon @click="close()" small>
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-divider></v-divider> -->
  <!-- <v-container fluid> -->
  <!-- <v-container fluid>
        <div class="custom-slot-content">
          <v-card-text class="description">
            <div class="description-content">
              <slot></slot>
            </div>
          </v-card-text>
        </div>
      </v-container> -->

  <!-- <div class="custom-slot-content">
        <slot></slot>
      </div> -->

  <!-- </v-container> -->
  <!-- <v-divider></v-divider> -->
  <!-- <v-card-actions class="align-right">
        <slot name="actions"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog> -->
</template>

<style>
/* .custom-slot-content {
  flex-grow: 1;
}

.description-content {
  overflow: auto;
} */
</style>

<script>
export default {
  name: "TheDialog",
  data: () => ({
    dialog: false,
  }),
  props: {
    value: Boolean,
    title: String,
    icon: String,
    width: {
      type: String,
      default: "700px",
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit('close-dialog')
      this.dialog = false
      this.externalClose()
    },
    externalClose() {
      this.$emit("closeFunction")
    },
  },
  watch: {
    dialog() {
      this.$emit("input", this.dialog)
    },
    value() {
      this.dialog = this.value
    },
  },
}
</script>
